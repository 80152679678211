<template>
  <div id="user.password.index">
      <router-view :key="$route.path" />
  </div>
</template>

<script>
export default {
    name: 'user.password.index'
}
</script>

<style>

</style>